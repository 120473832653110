.product-card{
    /* background:; */
    /* height:99%; */
    /* width:325px; */
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    padding:8px
    
}
.product-card {
    /* Your existing styles */
    transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
    transform: translateY(-5px); /* Moves the card up on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increases shadow on hover */
}

.product-card .cardbody{
    padding: 5px;

}

.product-card .cardimg{
    /* height:200px;
    width:300px; */
    position: relative;

}

.product-card .cardimg img{
    height:100%;
    width:100%;
    object-fit: cover;
}

.product-card .cardimg .imgbadges{
   
    
    
}
.meal-tags {
    margin-bottom: 1.2rem;
    display: flex;
    gap: 0.4rem;
  }
  
  .tag {
    display: inline-block;
    text-transform: uppercase;
    background-color: #51cf66;
    color: #333;
    border-radius: 100px;
    font-weight: 600;
  }
  
  .tag--vegetarian {
    background-color: #51cf66;
  }

  .tag--non-vegetarian {
    background-color: orangered;
    color: #f5f5f5;
  }

.product-card .cardimg .imgbadges .type{
    /* background: #22aa00; */
    /* padding:5px;
    position: absolute;
    top:0;
    width:70px ; */
}

.product-card .cardimg .imgbadges .type h3{
    color: white;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.product-card .Carddescription{
   
}

.product-card .Carddescription h2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.product-card .offer{
    background: rgb(203, 184, 11);
    height:60px;
    width:60px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: absolute;
    right: 6%;
    bottom: 14%;
    display:flex;
    justify-content: center;
    align-items: center;

}

.product-card .offer h3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder;
}

.product-card .order{
    background: rgb(182, 15, 15);
    padding:5px;
    /* width:29%; */
    border-radius: 5px;
    margin-top:10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
}

.product-card .order h1{
    color: whitesmoke;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder;
}



/* template.css */
.slick-next.slick-arrow::before, .slick-prev.slick-arrow::before {
    color: red; /* Change arrow color to red */
}
