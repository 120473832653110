    .navs > a {
        color: #333;
        text-decoration: none;
        display: inline-block;
        position: relative;
    }
    
    .navs > a::after {
        content: "";
        display: block;
        margin: auto;
        height: 3px;
        width: 0;
        top: 5px;
        background: transparent;
        transition: all 0.3s;
        
    }
    
    .navs > a:hover::after, .navs > a.active-nav::after {
        width: 100%;
        background: #D21F3C;
    }

    .glass{
        background: rgba( 255, 255, 255, 0.4 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border-radius: 10px;
        
    }
  

    

  .testimonials {
    background-color: #ffffff;
    /* width: 100%; */
    /* max-width: 740px; */
    margin: 20px;
    box-shadow: 0 6px 5px -7px #cbcbcb;
    border-radius: 3px;
    position: relative;
    opacity: 0;
    animation: showTestimonials 0.32s 0.78s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards;
    input {
      display: none;
      visibility: hidden;
      height: 0;
      width: 0;
      pointer-events: none;
      opacity: 0;
    }
  }
  
  @keyframes showTestimonials {
    to {
      opacity: 1;
    }
  }
  
  .testimonials-inner {
    position: relative;
    min-height: 100px;
  }
  
  .testimonial {
    /* padding: ; */
    /* margin: 0 30px; */
    pointer-events: none;
    position: relative;
    z-index: 1;
  }
  
  input[name="testimonial"]:nth-child(1):checked ~ .testimonials-inner .testimonial:nth-child(1),
  input[name="testimonial"]:nth-child(2):checked ~ .testimonials-inner .testimonial:nth-child(2),
  input[name="testimonial"]:nth-child(3):checked ~ .testimonials-inner .testimonial:nth-child(3),
  input[name="testimonial"]:nth-child(4):checked ~ .testimonials-inner .testimonial:nth-child(4) {
    pointer-events: initial;
  }
  
  .testimonial {
    transition: 0.72s cubic-bezier(0.215, 0.610, 0.355, 1), opacity 0.32s 0.12s;
    opacity: 0;
    transform: translate(0, 38px);
    max-height: 80px;
    animation: hideTestimonial 0s 0.52s forwards;
    position: relative;
  }
  
  input[name="testimonial"]:nth-child(1):checked ~ .testimonials-inner .testimonial:nth-child(1),
  input[name="testimonial"]:nth-child(2):checked ~ .testimonials-inner .testimonial:nth-child(2),
  input[name="testimonial"]:nth-child(3):checked ~ .testimonials-inner .testimonial:nth-child(3),
  input[name="testimonial"]:nth-child(4):checked ~ .testimonials-inner .testimonial:nth-child(4) {
    animation: showTestimonial 0s 0.72s forwards;
    position: absolute;
    opacity: 1;
    transform: none;
    max-height: 500px;
    transition: 0.3s 0.52s, opacity 0.68s 0.92s, max-height 0.82s 0.72s;
  }
  
  @keyframes hideTestimonial {
    to {
      position: absolute
    }
  }
  
  @keyframes showTestimonial {
    to {
      position: relative
    }
  }
  
  input[name="testimonial"]:nth-child(1):checked ~ .testimonials-bullets label:nth-child(1) span,
  input[name="testimonial"]:nth-child(2):checked ~ .testimonials-bullets label:nth-child(2) span,
  input[name="testimonial"]:nth-child(3):checked ~ .testimonials-bullets label:nth-child(3) span,
  input[name="testimonial"]:nth-child(4):checked ~ .testimonials-bullets label:nth-child(4) span {
    background-color: rgba(245, 34, 34, 0.836);
    transform: scale(1);
    transition: transform 0.36s cubic-bezier(0.26, -0.59, 0.43, 2.48), background-color 0.41s ease-in;
  }
    
  
  
  
  input[name="testimonial"]:nth-child(1):checked ~ .testimonials-inner .testimonial:nth-child(1) .photo-author,
  input[name="testimonial"]:nth-child(2):checked ~ .testimonials-inner .testimonial:nth-child(2) .photo-author,
  input[name="testimonial"]:nth-child(3):checked ~ .testimonials-inner .testimonial:nth-child(3) .photo-author,
  input[name="testimonial"]:nth-child(4):checked ~ .testimonials-inner .testimonial:nth-child(4) .photo-author {
    transform: scale(1);
    opacity: 1;
    height: 68px;
    width: 68px;
    transition: width 0.26s 1.01s, height 0.26s 1.01s;
  }
  
  .testimonial-text {
    color: #606060;
    /* font-size: 20px; */
    text-align: center; 
    text-shadow: 0 0 1px #939393;
    letter-spacing: 0.12px;
    font-style: italic;
    line-height: 26px;
    p {
      margin-bottom: 10px;
    }
  }
  
  .testimonial-author {
    font-size: 15px;
    text-align: right;
    color: #bdbdbd;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    text-shadow: 0 0 1px #e3e3e3;
    &:before {
      content: '– ';
    }
  }
  
  .testimonials-bullets {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
    transform: translate(0, 8px);
    .bullet {
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin: 0 1px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0 4px 6px -7px #000000;
        span {
          background-color: #c3c3c3;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          transform: scale(0.82);
          transition: 0.72s;
        }
      }
      &:hover span {
        background-color: rgba(216, 118, 118, 0.836);
      }
    }
  }
  
  .testimonials-arrows {
    height: 100%;
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      position: absolute;
      top: calc(50% - 25px);
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        &:before {
          font-size: 32px;
          font-family: monospace, sans-serif;
          font-weight: 600;
          color: #c3c3c3;
          transition: color 0.21s ease-in;
        }
      }
      &:hover span:before {
        color: #444444;
        transition: color 0.17s ease-out;
      }
      label {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
    .arrow-left {
      left: -20px;
      span {
        box-shadow: 40px 0 20px 20px #ffffff, -5px 0 30px 0px #e1e1e1;
        &:before {
          content: '<';
        }
      }
    }
    .arrow-right {
      right: -20px;
      span {
        box-shadow: -40px 0 20px 20px #ffffff, 5px 0 30px 0px #e1e1e1;
        &:before {
          content: '>';
        }
      }
    }
  }
  
  input[name="testimonial"]:nth-child(1):checked ~ .testimonials-arrows .arrow-left label[for="input-testimonial4"],
  input[name="testimonial"]:nth-child(1):checked ~ .testimonials-arrows .arrow-right label[for="input-testimonial2"],
  input[name="testimonial"]:nth-child(2):checked ~ .testimonials-arrows .arrow-left label[for="input-testimonial1"],
  input[name="testimonial"]:nth-child(2):checked ~ .testimonials-arrows .arrow-right label[for="input-testimonial3"],
  input[name="testimonial"]:nth-child(3):checked ~ .testimonials-arrows .arrow-left label[for="input-testimonial2"],
  input[name="testimonial"]:nth-child(3):checked ~ .testimonials-arrows .arrow-right label[for="input-testimonial4"],
  input[name="testimonial"]:nth-child(4):checked ~ .testimonials-arrows .arrow-left label[for="input-testimonial3"],
  input[name="testimonial"]:nth-child(4):checked ~ .testimonials-arrows .arrow-right label[for="input-testimonial1"] {
    z-index: 1;
  }
  
  