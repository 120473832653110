.rating-box {
    padding: 25px 50px;
    /* background-color: #f1f1f1; */
    border-radius: 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    text-align: center;

  }
  
  .rating-box h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .rating-box .stars {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  
  .stars i {
    font-size: 35px;
    color: #b5b8b1;
    transition: all 0.2s;
    cursor: pointer;
  }
  
  .stars i.active {
    color: #ffb851;
    transform: scale(1.2);
  }
  